<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo d-flex align-items-center justify-content-center">
          <b-img
            :src="appLogoImage"
            alt="logo"
            width="150"
          />
        </b-link>

        <b-card-title class="mb-1">
          Mot de passe oublié ?
        </b-card-title>
        <b-card-text class="mb-2">
          Entrez votre email et nous vous enverrons les instructions par email afin de réinitialiser votre mot de passe
        </b-card-text>
        <b-alert
          v-if="error"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Erreur
          </h4>
          <div class="alert-body">
            <span>{{ error.message }}</span>
          </div>
        </b-alert>
        <b-alert
          v-if="success"
          variant="success"
          show
        >
          <div class="alert-body">
            <span>Vous allez recevoir un email contenant les informations pour générer un nouveau mot de passe. (Pensez à vérifier les spams)</span>
          </div>
        </b-alert>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="paul@emailtest.fr"
                />
                <small class="text-danger">Email obligatoire</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Envoyer les instructions
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Retour à la page de connexion
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BAlert, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BImg,
    BLink,
    BAlert,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    const { appLogoImage, appName } = $themeConfig.app
    return {
      userEmail: '',
      error: null,
      success: null,
      // validation
      required,
      email,
      appLogoImage,
      appName,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt.resetPasswordRequest({
            email: this.userEmail,
          })
            .then(response => {

              this.success = true
              this.error = false
            })
            .catch(error => {

              this.success = false
              this.error = error.response.data
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
